import { createAsync, useLocation } from '@solidjs/router';
import { cachedGet } from '@troon/api-client';
import { Title } from '@solidjs/meta';
import { BreadcrumbItem, Breadcrumbs, Button, Heading, Link, Tab, TabList, Tabs, Tag } from '@troon/ui';
import { createMemo, For, Show, Suspense } from 'solid-js';
import dayjs from '@troon/dayjs';
import { Content } from '../../../../../components/main-content';
import { useCurrentFacility, useUser } from '../../../../../providers/root';
import { TroonAccessTag } from '../../../../../components/troon-access-tag';
import { RedeemGuestPasses } from './[rewardsId]/components/redeem-guest-passes';
import type { RouteSectionProps } from '@solidjs/router';

export default function Customer(props: RouteSectionProps) {
	const user = createAsync(() => getUser({ path: { rewardsId: props.params.rewardsId! } }), { deferStream: true });
	const location = useLocation<{ query?: string }>();
	const facility = useCurrentFacility();
	const operator = useUser();

	const format = new Intl.NumberFormat('en-us').format;

	const accessParams = createMemo(
		() =>
			new URLSearchParams({
				rewardsId: props.params.rewardsId!,
				firstName: user()?.firstName ?? '',
				lastName: user()?.lastName ?? '',
				email: user()?.email ?? '',
				facilityId: facility()?.facility.id ?? '',
				operatorId: operator()?.id ?? '',
				operatorName: `${operator()?.firstName} ${operator()?.lastName}`,
			}),
	);

	return (
		<>
			<Content class="border-b border-neutral bg-white pb-0 lg:pb-0 2xl:pb-0">
				<Title>
					{user()?.firstName} {user()?.lastName} | Troon Operator
				</Title>
				<Breadcrumbs omitHome>
					<BreadcrumbItem href={`/facility/${props.params.facility}/customer?query=${location.state?.query}`}>
						Customers
					</BreadcrumbItem>
					<BreadcrumbItem href={`/facility/${props.params.facility}/customer/${props.params.rewardsId}`}>
						{user()?.firstName} {user()?.lastName}
					</BreadcrumbItem>
				</Breadcrumbs>
				<div class="flex flex-wrap items-center justify-between gap-4 border-b border-neutral pb-8">
					<div class="flex flex-col gap-4">
						<div class="flex gap-2">
							<TroonAccessTag product={user()?.accessProductType} />
							<For each={user()?.programs}>{(program) => <Tag appearance="neutral">{program.name}</Tag>}</For>
						</div>
						<Heading as="p" size="h1">
							{user()?.firstName} {user()?.lastName}
						</Heading>
						<p>{user()?.email}</p>
					</div>
					<Show when={user()?.availableGuestPasses ?? 0 > 0}>
						<RedeemGuestPasses {...user()!} />
					</Show>
					<Show when={!user()?.accessProductType}>
						<Button
							as={Link}
							href={`https://troon.com/_/associate/access?${accessParams().toString()}`}
							class="size-fit shrink grow-0"
						>
							Purchase Troon Access
						</Button>
					</Show>
				</div>

				<div class="flex flex-wrap gap-y-2 divide-x divide-neutral [&>div:first-child]:ps-0 [&>div]:px-4 [&>div]:2xl:px-12">
					<div class="flex flex-col gap-2">
						<span class="whitespace-nowrap text-sm font-medium text-neutral-700">Rewards #</span>
						<span class="text-xl font-semibold">{user()?.rewardsId}</span>
					</div>
					<div class="flex flex-col gap-2">
						<span class="whitespace-nowrap text-sm font-medium text-neutral-700">Current Status</span>
						<span class="text-xl font-semibold">{user()?.points.rewardsLevel}</span>
					</div>
					<div class="flex flex-col gap-2">
						<span class="whitespace-nowrap text-sm font-medium text-neutral-700">Available Points</span>
						<span class="text-xl font-semibold">{format(user()?.points.availablePoints ?? 0)}</span>
					</div>
					<div class="flex flex-col gap-2">
						<span class="whitespace-nowrap text-sm font-medium text-neutral-700">Available Guest Passes</span>
						<span class="text-xl font-semibold">{format(user()?.availableGuestPasses ?? 0)}</span>
					</div>
					<div class="flex flex-col gap-2">
						<span class="whitespace-nowrap text-sm font-medium text-neutral-700">Rewards Member Since</span>
						<span class="text-xl font-semibold">{dayjs(user()?.signupDate).format('M/D/YYYY')}</span>
					</div>
				</div>

				<Tabs defaultValue={location.pathname.split('/')[location.pathname.split('/').length - 1]}>
					<TabList aria-label="Customer details" class="border-b-0">
						<Tab
							as={Link}
							href={`/facility/${props.params.facility}/customer/${props.params.rewardsId}`}
							value="overview"
						>
							Overview
						</Tab>
						<Tab
							as={Link}
							href={`/facility/${props.params.facility}/customer/${props.params.rewardsId}/info`}
							value="info"
						>
							Personal Info
						</Tab>
					</TabList>
				</Tabs>
			</Content>
			<Content>
				<Suspense>{props.children}</Suspense>
			</Content>
		</>
	);
}

const getUser = cachedGet('/v0/operator/user/{rewardsId}');
