import { useSubmission } from '@solidjs/router';
import { clientAction } from '@troon/api-client';
import { createEffect, createMemo, createSignal, Show } from 'solid-js';
import {
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	DialogTrigger,
	Errors,
	FieldDescription,
	Form,
	HiddenFields,
	Input,
	Label,
	MessageBar,
	TextField,
} from '@troon/ui';
import { IconCircleCheck } from '@troon/icons/circle-check';
import { IconInfo } from '@troon/icons/info';
import type { ApiResponse } from '@troon/api-client';

export function RedeemGuestPasses(props: ApiResponse<'get', '/v0/operator/user/{rewardsId}'>) {
	const [showDialog, setShowDialog] = createSignal(false);
	const submission = useSubmission(redeemGuestPasses);

	createEffect(() => {
		if (showDialog() === false) {
			submission.clear();
		}
	});

	const availableGuestPasses = createMemo(() => props.availableGuestPasses ?? 0);

	return (
		<Dialog key="operator-redeem-guest-passes" open={showDialog()} onOpenChange={setShowDialog}>
			<DialogTrigger class="size-fit grow-0" appearance="secondary">
				Redeem Guest Passes
			</DialogTrigger>
			<DialogContent height="fit" header="Redeem Guest Passes" headerLevel="h2">
				<Show
					when={submission.result}
					fallback={
						<Form action={redeemGuestPasses}>
							<HiddenFields
								data={{
									rewardsId: props.rewardsId,
								}}
							/>
							<TextField name="count">
								<Label>How many guest passes does {props.firstName} want to use?</Label>
								<Input
									class="text-2xl"
									name="count"
									type="number"
									value={1}
									min={1}
									max={availableGuestPasses()}
									autofocus={false}
								/>
								<FieldDescription>
									There are <b>{availableGuestPasses()}</b>{' '}
									{availableGuestPasses() === 1 ? 'guest pass' : 'guest passes'} remaining.
								</FieldDescription>
							</TextField>

							<MessageBar appearance="brand" icon={IconInfo}>
								<p class="text-sm">
									Feel free to remind {props.firstName} that they can always share a guest pass on troon.com or in the
									iOS app to speed up the check-in process!
								</p>
							</MessageBar>

							<Errors />

							<div class="flex w-full items-end justify-end">
								<DialogActions>
									<DialogAction type="button" appearance="secondary" onClick={() => setShowDialog(false)}>
										Cancel
									</DialogAction>
									<DialogAction type="submit">Redeem</DialogAction>
								</DialogActions>
							</div>
						</Form>
					}
				>
					<div class="flex flex-col gap-8">
						<MessageBar icon={IconCircleCheck} appearance="brand">
							<p>Redeemed guest passes!</p>
						</MessageBar>

						<DialogActions>
							<DialogAction type="button" class="w-full" onClick={() => setShowDialog(false)}>
								Done
							</DialogAction>
						</DialogActions>
					</div>
				</Show>
			</DialogContent>
		</Dialog>
	);
}

const redeemGuestPasses = clientAction(
	'POST',
	'/v0/operator/user/{rewardsId}/claim-guest-passes',
	(data) => ({
		params: {
			path: { rewardsId: data.get('rewardsId') as string },
		},
	}),
	{
		transformer: (data) => ({
			count: parseInt(data.get('count') as string),
		}),
		revalidate: ['/v0/operator/user/{rewardsId}'],
	},
);
